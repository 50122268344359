
  // Seleciona o menu
  var lastId,
  topMenu = $("header nav.navbar"),
  topMenuHeight = topMenu.outerHeight(),
  // Lista de itens do menu
  menuItems = topMenu.find("a"),
  // Âncoras dos itens
  scrollItems = menuItems.map(function(){
    var item = $($(this).attr("href"));
    if (item.length) { return item; }
  });

  // Realiza animação ao clicar
  menuItems.click(function(e){
    var href = $(this).attr("href"),
        offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
    $('html, body').stop().animate({
        scrollTop: offsetTop
    }, 800);
    e.preventDefault();
});

// Verifica scroll
$(window).scroll(function(){

   var fromTop = $(this).scrollTop()+topMenuHeight;


   var cur = scrollItems.map(function(){
     if ($(this).offset().top < fromTop)
       return this;
   });

   cur = cur[cur.length-1];
   var id = cur && cur.length ? cur[0].id : "";

   if (lastId !== id) {
       lastId = id;

       menuItems
         .parent().removeClass("active")
         .end().filter("[href=#"+id+"]").parent().addClass("active");
   }
});

$(".more-info-toggle").click(function(){
    $(".more-info").slideToggle();
});

function openGallery(category, project){
  gallery = $(this).lightGallery({
    download: false,
    dynamic: true,
    share: false,
    dynamicEl: category == 'heavy_works' ? finalHwGallery[project] : finalGallery[project]
  });
  gallery.on('onBeforeClose.lg',function(event, index, fromTouch, fromThumb){
    gallery.data('lightGallery').destroy(true);
  });
}

$(document).ready(function() {

  if (typeof hpGallery !== 'undefined') {

    finalGallery = [];
    $.each(hpGallery, function(i, project) {
      finalGallery[i] = [];
      $.each(project, function(j, galleryItem) {
        finalGallery[i].push({
          "src": baseUrl + "/" + galleryItem.path,
          "thumb": baseUrl + "/" + galleryItem.path,
          "subHtml": "Usinas Hidrelétricas"
        });
      });
    })
  }

  if (typeof hwGallery !== 'undefined') {

    finalHwGallery = [];
    $.each(hwGallery, function(i, project) {
      finalHwGallery[i] = [];
      $.each(project, function(j, galleryItem) {
        finalHwGallery[i].push({
          "src": baseUrl + "/" + galleryItem.path,
          "thumb": baseUrl + "/" + galleryItem.path,
          "subHtml": "Obras Pesadas"
        });
      });
    })
  }

  $(".project .gallery").lightGallery({
    download: false,
    share: false,
    thumbnail: true,
    exThumbImage: 'data-exthumbimage',
  });


  $('#customers .gallery1').slick({
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      swipe: false,
      autoplaySpeed: 2000,
      asNavFor: '#customers .gallery2, #customers .gallery3',
      responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });

  $('#customers .gallery2').slick({
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      swipe: false,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });

  $('#customers .gallery3').slick({
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      swipe: false,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });

  $('#customers .arrow-left').click(function(){
    $('#customers .gallery1').slick('slickPrev');

  });

  $('#customers .arrow-right').click(function(){
    $('#customers .gallery1').slick('slickNext');
  });

  // function loadPhotos(category){

  //   loadAllowed = 0;

  //   if(category == "completed" && completedFull == 0){
  //     loadAllowed = 1;
  //   }else if(category == "progress" && progressFull == 0){
  //     loadAllowed = 1;
  //   }else if(category == "3d" && thirdFull == 0){
  //     loadAllowed = 1;
  //   }else if(category == "2d" && secondFull == 0){
  //     loadAllowed = 1;
  //   }

  //   if(loadAllowed){
  //     $("#"+ category +"-loader").css("display", "block");
  //     $.getJSON(
  //         "photos/category/"+ category +"/page/" + photoPage,
  //         function(data){
  //             photos = data.data;
  //             console.log(photos);
  //             item = $("<div class='slider-item'></div>");
  //               $.each(photos, function(i, photo){
  //                 item.append("<div class='col-xs-6'><a href='"+ photo.path +"' class='thumbnail fancybox'rel='"+ category +"' title='"+ photo.name +"' style='background: url("+ photo.path +")'></a></div>");
  //               });

  //           if(photos.length != 0){
  //             photoPage++;
  //             photoCount++;
  //             $("#" + category + " .gallery").slick("slickAdd", item);
  //           }else{
  //             if(category == "completed"){
  //               completedFull = 1;
  //             }else if(category == "progress"){
  //               progressFull = 1;
  //             }else if(category == "3d"){
  //               thirdFull = 1;
  //             }else if(category == "2d"){
  //               secondFull = 1;
  //             }
  //           }
  //           $("#"+ category +"-loader").css("display", "none");
  //           $("#" + category + " .gallery").slick('slickNext');
  //         }
  //     );
  //   }else{
  //     $("#" + category + " .gallery").slick('slickNext');
  //   }

  // }

  $('.album').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    console.log(nextSlide+1);
    console.log(4 * (photoPage-1));
    if(nextSlide+1 == 4 * (photoPage-1)){
      showAlbum(oldAlbumId);
    }
  });

    // process the form
    $('form').submit(function(event) {

      event.preventDefault();

        // get the form data
        // there are many ways to get this data using jQuery (you can use the class or id also)

        $("#contact-result").slideUp();
        $("#contact-loader").fadeIn();

        // name = $('input[name=name]').val();
        // email = $('input[name=email]').val();
        // message = $('textarea[name=message]').val();
        token = $("input[name=_token]").val();

        // process the form
        $.ajax({
            headers: {'X-CSRF-TOKEN': token},
            type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
            url         : baseUrl + 'contact', // the url where we want to POST
            // data        : {_token: token, name: name, email: email, message: message},
            data        : $('form').serialize(),
            dataType    : 'json', // what type of data do we expect back from the server
            encode          : true,
            success : function(data){
              $("#contact-loader").slideUp();
              $("#contact-result").text(data.contact.message)
              if(data.contact.result == "1"){
                $("#contact-result").removeClass("alert-danger");
                $("#contact-result").addClass("alert-success");
              }else{
                $("#contact-result").removeClass("alert-success");
                $("#contact-result").addClass("alert-danger");
              }
              $('#contact form').trigger("reset");
              $("#contact-result").fadeIn();
            },
            error : function(){
              $("#contact-loader").slideUp();
              $("#contact-result").text("Erro ao enviar mensagem. Verifique o preenchimento do formulário.");
              $("#contact-result").removeClass("alert-success");
              $("#contact-result").addClass("alert-danger");
              $("#contact-result").fadeIn();
            }
        })

        event.preventDefault();
    });

});
